import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus({ type: 'success', message: 'Email sent successfully!' });
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus({ type: 'error', message: 'Failed to send email. Please try again.' });
      }
    } catch (error) {
      setStatus({ type: 'error', message: 'An error occurred. Please try again.' });
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: '85vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={4}
        borderRadius={2}
        boxShadow={3}
        bgcolor="#fff"
        width="100%"
      >
        <Typography variant="h5" mb={2}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            name="name"
            placeholder="Name"
            InputLabelProps={{ shrink: false }}
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            name="email"
            type="email"
            placeholder="Email"
            InputLabelProps={{ shrink: false }}
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            name="message"
            placeholder="Message"
            InputLabelProps={{ shrink: false }}
            value={formData.message}
            onChange={handleChange}
            required
            multiline
            rows={5} // Slightly taller message box
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              marginTop: 2,
              backgroundColor: 'goldenrod',
              color: 'white',
              '&:hover': {
                backgroundColor: 'grey',
              }
            }}
          >
            Send
          </Button>
        </form>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={status.type} sx={{ width: '100%' }}>
          {status.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;