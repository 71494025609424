// src/components/LandingPage.js

import React, { useState, useEffect, useCallback } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import './LandingPage.css';
import axios from 'axios';
import ResultPopup from './ResultPopup';
import SearchIcon from '@mui/icons-material/Search';

// Icons
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ChurchIcon from '@mui/icons-material/Church';
import SchoolIcon from '@mui/icons-material/School';
import ParkIcon from '@mui/icons-material/Park';
import TerrainIcon from '@mui/icons-material/Terrain';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import WaterIcon from '@mui/icons-material/Opacity';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import GavelIcon from '@mui/icons-material/Gavel';

import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// --- MAPBOX ---
import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// ** Import your marker icon here **
import markerIcon from './marker-icon.png';

const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ||
  'pk.eyJ1IjoidHJleWRpY2tzIiwiYSI6Im...'; // fallback token

const LandingPage = ({ onSearch }) => {
  const [searchInput, setSearchInput] = useState('');
  const [topRatedParcels, setTopRatedParcels] = useState([]);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [userStatus, setUserStatus] = useState('UNAUTHENTICATED');

  // Helper: format numbers to two decimals
  const formatNumber = (num) => {
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum)) return 'N/A';
    return parsedNum.toFixed(2);
  };

  // Fetch user status
  const fetchUserStatus = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setUserStatus('UNAUTHENTICATED');
      return;
    }
    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getUserStatus',
        tok: token,
      });
      const statusCode = response.data.status;

      let status;
      switch (statusCode) {
        case 20:
          status = 'UNVERIFIED';
          break;
        case 30:
          status = 'VERIFIED';
          break;
        case 40:
          status = 'PAID';
          break;
        case 90:
          status = 'ADMIN';
          break;
        default:
          status = 'UNKNOWN';
      }
      setUserStatus(status);
    } catch (error) {
      console.error('Failed to fetch user status:', error);
      setUserStatus('UNAUTHENTICATED');
    }
  }, []);

  useEffect(() => {
    fetchUserStatus();
  }, [fetchUserStatus]);

  // Fetch top-rated parcels
  useEffect(() => {
    const fetchTopRatedParcels = async () => {
      try {
        const response = await axios.post('https://api.developiq.org/api', {
          m: 'getTopRatedParcels',
        });
        const parcels = response.data.filter(
          (parcel) => parcel.Latitude && parcel.Longitude
        );
        setTopRatedParcels(parcels);
      } catch (error) {
        console.error('Failed to fetch top-rated parcels:', error);
      }
    };
    fetchTopRatedParcels();
  }, []);

  const handleSearchClick = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to view property details.');
      return;
    }
    if (searchInput.trim()) {
      onSearch(searchInput);
    }
  };

  const handleParcelClick = async (parcel) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Please log in to view property details.');
      return;
    }
    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getFullListing',
        h: parcel.h,
        tok: token,
      });
      const fullDetails = response.data;
      setSelectedParcel({ ...parcel, ...fullDetails });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('You need a paid account to view full property details.');
      } else {
        console.error(
          'Failed to get full listing:',
          error.response?.data || error.message
        );
      }
    }
  };

  const handleClosePopup = () => {
    setSelectedParcel(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  /***************************************************************
   * Items (No Floodplains). We have 9 items total, split 5/4
   ***************************************************************/
  const infoItemsRow1 = [
    { id: 1, icon: <PlumbingIcon />, text: 'Sewage Access' },
    { id: 2, icon: <WaterIcon />, text: 'Water Access' },
    { id: 3, icon: <GavelIcon />, text: 'Zoning Regulations' },
    { id: 4, icon: <LocalHospitalIcon />, text: 'Closest Hospital' },
    { id: 5, icon: <ChurchIcon />, text: 'Closest Religious Building' },
  ];

  const infoItemsRow2 = [
    { id: 6, icon: <SchoolIcon />, text: 'Closest School' },
    { id: 7, icon: <ParkIcon />, text: 'Closest Park' },
    { id: 8, icon: <TerrainIcon />, text: 'Topography' },
    { id: 9, icon: <PriceCheckIcon />, text: 'Price Comparison' },
  ];

  return (
    <div className="landing-page">
      {/* --- Hero Section --- */}
      <div className="overlay">
        <h1>Find Your Perfect Parcel</h1>
        <p>Explore the best properties in your desired location.</p>
        <div className="search-bar">
          <TextField
            placeholder="Enter an address, zip code, or city"
            variant="outlined"
            fullWidth
            value={searchInput}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '25px',
                paddingRight: '8px',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: 'black',
              },
            }}
          />
        </div>
      </div>

      {/* --- Top Rated Parcels --- */}
      <div className="section popular-searches">
        <h2>Top Rated Parcels</h2>
        <div className="parcels-list">
          {topRatedParcels.map((parcel) => (
            <div
              key={parcel.h}
              className="parcel-card"
              onClick={() => handleParcelClick(parcel)}
            >
              {/* Mapbox Map for each parcel */}
              <div className="parcel-map">
                <ParcelMap
                  parcel={parcel}
                  onParcelClick={() => handleParcelClick(parcel)}
                />
              </div>
              <div className="parcel-text">
                <h3>{parcel.Full_Address}</h3>
                <p>Score: {parcel.Score ? formatNumber(parcel.Score) : 'N/A'}</p>
                <p>
                  Acreage:{' '}
                  {parcel.Lot_Acreage ? formatNumber(parcel.Lot_Acreage) : 'N/A'}
                </p>
                <p>
                  Price: $
                  {parcel.List_Price
                    ? parseFloat(formatNumber(parcel.List_Price)).toLocaleString()
                    : 'N/A'}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* --- About Us Section --- */}
      <div className="section about-us">
        <h2>About Us</h2>
        <p>
          At DevelopIQ, we are dedicated to helping developers and real estate
          professionals find the perfect land for their projects. Our platform
          offers advanced tools and resources to make the search process easy,
          efficient, and successful. Whether you're looking for commercial,
          residential, or industrial land, DevelopIQ is here to support your
          journey from search to acquisition.
        </p>
      </div>

      {/* --- Information We Offer (two rows: 5 items, 4 items) --- */}
      <div className="section information-we-offer">
        <h2>Information We Offer</h2>

        {/* Row 1 => 5 items */}
        <div
          className="info-row"
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          {infoItemsRow1.map((item) => (
            <div key={item.id} className="info-item">
              <div className="info-icon">{item.icon}</div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>

        {/* Row 2 => 4 items */}
        <div
          className="info-row"
          style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}
        >
          {infoItemsRow2.map((item) => (
            <div key={item.id} className="info-item">
              <div className="info-icon">{item.icon}</div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      {selectedParcel && (
        <ResultPopup
          place={selectedParcel}
          onClose={handleClosePopup}
          userStatus={userStatus}
          token={localStorage.getItem('token')}
        />
      )}

      {/* --- Footer --- */}
      <Box
        component="footer"
        sx={{
          backgroundColor: 'rgb(238, 238, 238)',
          padding: '20px',
          paddingBottom: '40px',
          borderTop: '1px solid #ddd',
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            © 2024 DevelopIQ. All rights reserved.
          </Typography>
          <Link
            to="/contact"
            style={{
              color: '#007bff',
              textDecoration: 'none',
              fontWeight: 'bold',
              margin: '30px',
              marginTop: '10px',
              display: 'inline-block',
            }}
          >
            Contact Us
          </Link>
        </div>
      </Box>
    </div>
  );
};

export default LandingPage;

/****************************************
 * Child component: a small Mapbox map
 ****************************************/
const ParcelMap = ({ parcel, onParcelClick }) => {
  const [viewport, setViewport] = useState({
    latitude: parseFloat(parcel.Latitude),
    longitude: parseFloat(parcel.Longitude),
    zoom: 13,
  });

  return (
    <div style={{ width: '100%', height: '200px' }}>
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={MAPBOX_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
      >
        <Marker
          /*  Use the parcel's actual lat/lon here  */
          latitude={parseFloat(parcel.Latitude)}
          longitude={parseFloat(parcel.Longitude)}
          /*
            offsetLeft/Top if you want the bottom of the icon 
            to point exactly at the coordinate
          */
          offsetLeft={-12}
          offsetTop={-24}
        >
          <div onClick={onParcelClick} style={{ cursor: 'pointer' }}>
            <img
              src={markerIcon} 
              alt="Marker"
              style={{ width: 32, height: 32 }}
            />
          </div>
        </Marker>
      </ReactMapGL>
    </div>
  );
};
