// src/components/MapboxInterface.js
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import markerIcon from './marker-icon.png'; // Adjust if needed

// Replace with your actual Mapbox token (or keep it here if you must)
mapboxgl.accessToken = 'pk.eyJ1IjoidHJleWRpY2tzIiwiYSI6ImNtNHEweXNxaTEyOWgya29yeGVkdjg5emkifQ.NThN-TEyUeDDx8Ie0R7OUQ';

// Example overlays: water or sewer
const GEOJSON_URLS = [
  { url: '/geojson/water/bellavista_water.geojson', type: 'water', id: 'bellavista_water' },
  { url: '/geojson/water/fayetteville_water.geojson', type: 'water', id: 'fayetteville_water' },
  { url: '/geojson/water/rogers_water.geojson', type: 'water', id: 'rogers_water' },
  { url: '/geojson/water/springdale_water.geojson', type: 'water', id: 'springdale_water' },
  { url: '/geojson/sewer/fayetteville_sewer.geojson', type: 'sewer', id: 'fayetteville_sewer' },
  { url: '/geojson/sewer/rogers_sewer.geojson', type: 'sewer', id: 'rogers_sewer' },
  { url: '/geojson/sewer/springdale_sewer.geojson', type: 'sewer', id: 'springdale_sewer' },
];

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
};

const MapboxInterface = ({ places, showUtilities, showTopography, onMarkerClick }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const navControlRef = useRef(null);
  const markersRef = useRef([]);

  // Add GeoJSON utility layers
  const addGeoJSONLayers = () => {
    GEOJSON_URLS.forEach(({ url, type, id }) => {
      // Create source if it doesn't exist
      if (!mapRef.current.getSource(id)) {
        mapRef.current.addSource(id, { type: 'geojson', data: url });
      }
      // Create layer if it doesn't exist
      if (!mapRef.current.getLayer(id)) {
        mapRef.current.addLayer({
          id,
          type: 'line',
          source: id,
          paint: {
            'line-color': type === 'water' ? '#007bff' : '#28a745', // Blue for water, green for sewer
            'line-width': 2,
          },
          layout: {
            visibility: showUtilities ? 'visible' : 'none',
          },
        });
        console.log(`Layer ${id} added`);
      }
    });
  };

  // Clear existing markers
  const clearMarkers = () => {
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
  };

  // Add a marker for each valid place
  const addMarkers = () => {
    if (!Array.isArray(places)) return;

    clearMarkers();
    places.forEach((place) => {
      const lat = Number(place.Latitude);
      const lng = Number(place.Longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const el = document.createElement('img');
        el.src = markerIcon;
        el.alt = 'Property Marker';
        el.style.width = '32px';
        el.style.height = '32px';
        el.style.cursor = 'pointer';

        const marker = new mapboxgl.Marker(el)
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(`
              <h3>${place.Full_Address || 'Unknown Address'}</h3>
              <p>Price: $${place.List_Price?.toLocaleString() || 'N/A'}</p>
              <p>Acreage: ${place.Lot_Acreage?.toFixed(2) || 'N/A'}</p>
            `)
          )
          .addTo(mapRef.current);

        // Clicking the marker calls parent's onMarkerClick
        el.addEventListener('click', () => onMarkerClick(place));

        markersRef.current.push(marker);
      }
    });
  };

  // Adjust the map view based on the places
  const fitMapToBounds = () => {
    if (!mapRef.current) return;

    // If no places => fallback
    if (!Array.isArray(places) || places.length === 0) {
      mapRef.current.flyTo({ center: [-94.1719, 36.0666], zoom: 9 });
      return;
    }

    // Gather valid coords
    const validCoords = places
      .map((p) => [Number(p.Longitude), Number(p.Latitude)])
      .filter(([lng, lat]) => !isNaN(lng) && !isNaN(lat));

    if (validCoords.length === 0) {
      // No valid coords => fallback
      mapRef.current.flyTo({ center: [-94.1719, 36.0666], zoom: 9 });
      return;
    }

    // If exactly one coordinate => center and zoom in
    if (validCoords.length === 1) {
      const [lng, lat] = validCoords[0];
      mapRef.current.flyTo({ center: [lng, lat], zoom: 14 });
      return;
    }

    // Otherwise, fit bounds for multiple points
    const bounds = new mapboxgl.LngLatBounds();
    validCoords.forEach(([lng, lat]) => bounds.extend([lng, lat]));
    mapRef.current.fitBounds(bounds, { padding: 50 });
  };

  // Initialize the map only once
  useEffect(() => {
    if (mapRef.current || !mapContainerRef.current) return;

    // Create the map
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: showTopography
        ? 'mapbox://styles/mapbox/outdoors-v12'
        : 'mapbox://styles/mapbox/streets-v12',
      center: [-94.1719, 36.0666],
      zoom: 9,
    });

    if (!navControlRef.current) {
      navControlRef.current = new mapboxgl.NavigationControl();
      mapRef.current.addControl(navControlRef.current, 'top-right');
    }

    // Once style is loaded, add layers, markers, then fit
    mapRef.current.on('load', () => {
      addGeoJSONLayers();
      addMarkers();
      fitMapToBounds();
    });
  }, [showTopography]);

  // If topography toggle changes => setStyle again
  useEffect(() => {
    if (!mapRef.current) return;

    const newStyle = showTopography
      ? 'mapbox://styles/mapbox/outdoors-v12'
      : 'mapbox://styles/mapbox/streets-v12';

    mapRef.current.setStyle(newStyle);

    // Re-init layers, markers when style is ready
    mapRef.current.once('styledata', () => {
      if (navControlRef.current) {
        mapRef.current.addControl(navControlRef.current, 'top-right');
      }
      addGeoJSONLayers();
      addMarkers();
      fitMapToBounds();
    });
  }, [showTopography]);

  // If utilities toggle changes => show/hide each layer
  useEffect(() => {
    if (!mapRef.current || !mapRef.current.isStyleLoaded()) return;

    GEOJSON_URLS.forEach(({ id }) => {
      if (mapRef.current.getLayer(id)) {
        mapRef.current.setLayoutProperty(
          id,
          'visibility',
          showUtilities ? 'visible' : 'none'
        );
      }
    });
    // Typically no need to refit on utility toggle, but you could:
    // fitMapToBounds();
  }, [showUtilities]);

  // If places change, re-add markers & refit
  useEffect(() => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      addMarkers();
      fitMapToBounds();
    }
  }, [places]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  return <div ref={mapContainerRef} style={mapContainerStyle} />;
};

export default MapboxInterface;
