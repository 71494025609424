// src/components/ResultPopup.js

import React, { useState, useEffect, useRef } from 'react';
import './ResultPopup.css';
import { Switch } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ReactMapGL, { Marker, Source, Layer, Popup } from 'react-map-gl';
import markerIcon from './marker-icon.png';
import { useJsApiLoader } from '@react-google-maps/api';

// Keep libraries as a constant array so it doesn't re-render the script
const GOOGLE_MAPS_LIBRARIES = [];

// Environment variables from .env
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const GOOGLE_STREET_VIEW_API_KEY = process.env.REACT_APP_GOOGLE_STREET_VIEW_API_KEY;

// Overlays (if you have any water/sewer/city configs, add them here)
const OVERLAY_CONFIGS = [
  { city: 'fayetteville', type: 'water', url: '/geojson/water/fayetteville_water.geojson', id: 'fayetteville_water' },
  { city: 'fayetteville', type: 'sewer', url: '/geojson/sewer/fayetteville_sewer.geojson', id: 'fayetteville_sewer' },
  { city: 'rogers', type: 'water', url: '/geojson/water/rogers_water.geojson', id: 'rogers_water' },
  { city: 'rogers', type: 'sewer', url: '/geojson/sewer/rogers_sewer.geojson', id: 'rogers_sewer' },
  { city: 'springdale', type: 'water', url: '/geojson/water/springdale_water.geojson', id: 'springdale_water' },
  { city: 'springdale', type: 'sewer', url: '/geojson/sewer/fayetteville_water.geojson', id: 'springdale_sewer' },
  { city: 'bellavista', type: 'water', url: '/geojson/water/bellavista_water.geojson', id: 'bellavista_water' },
];

/**
 * Displays Street View if found within a certain radius. 
 * If none found, displays the fallback image instead of a black screen.
 */
function StreetViewManual({ lat, lng, fallbackImageUrl }) {
  const containerRef = useRef(null);
  const [panoramaError, setPanoramaError] = useState(false);

  useEffect(() => {
    if (window.google && window.google.maps && containerRef.current) {
      setPanoramaError(false); // reset on new lat/lng

      const service = new window.google.maps.StreetViewService();
      const radius = 50; // increase if you need a bigger search area

      service.getPanorama({ location: { lat, lng }, radius }, (data, status) => {
        if (status === window.google.maps.StreetViewStatus.OK) {
          new window.google.maps.StreetViewPanorama(containerRef.current, {
            pano: data.location.pano,
            pov: { heading: 0, pitch: 0 },
            zoom: 1,
            addressControl: true,
            fullscreenControl: true,
          });
        } else {
          console.warn('No Street View found within radius:', status);
          setPanoramaError(true);
        }
      });
    }
  }, [lat, lng]);

  // If Street View fails, show fallback image
  if (panoramaError) {
    return (
      <img
        src={fallbackImageUrl}
        alt="Fallback for Street View"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onError={(e) => {
          // If fallback also fails to load
          console.error('Fallback image failed to load.');
          e.target.style.display = 'none';
        }}
      />
    );
  }

  // Otherwise, show Street View container
  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#333',
      }}
    />
  );
}

const ResultPopup = ({ place, onClose, userStatus, token }) => {
  const [showUtilities, setShowUtilities] = useState(false);
  const [showTopography, setShowTopography] = useState(false);
  const [loadedOverlays, setLoadedOverlays] = useState([]);
  const [showMarkerPopup, setShowMarkerPopup] = useState(false);

  // Determine if user is paid
  const isPaidUser = userStatus === 'PAID' || userStatus === 'ADMIN';

  // Mapbox viewport
  const [viewport, setViewport] = useState({
    latitude: place?.Latitude ? Number(place.Latitude) : 36.1498,
    longitude: place?.Longitude ? Number(place.Longitude) : -94.1489,
    zoom: 14,
    bearing: 0,
    pitch: 0,
  });

  // Street View coverage
  const [hasStreetView, setHasStreetView] = useState(false);

  // Fallback photo
  const originalImageUrl =
    place?.Primary_Photo ||
    (place?.Alt_Photos ? place.Alt_Photos.split(', ')[0] : '') ||
    '';

  // Load Google script just to check coverage
  const { isLoaded: googleMapsLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_STREET_VIEW_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  // Helper to format numbers
  const formatNumber = (num) => {
    const parsed = parseFloat(num);
    if (isNaN(parsed)) {
      return 'Currently Unavailable - Refer to your local municipality';
    }
    return parsed.toFixed(2);
  };

  // Geocode function
  const geocodeAddress = async (address) => {
    if (!address) return null;
    const encoded = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encoded}&key=${GOOGLE_STREET_VIEW_API_KEY}`;
    try {
      const resp = await fetch(url);
      const data = await resp.json();
      if (data.status === 'OK' && data.results.length > 0) {
        return data.results[0].geometry.location;
      }
      return null;
    } catch (err) {
      console.error('Geocode error =>', err);
      return null;
    }
  };

  // Check Street View availability
  const checkStreetViewAvailabilityByAddress = async (address) => {
    const loc = await geocodeAddress(address);
    if (!loc) return false;

    const metaUrl = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${loc.lat},${loc.lng}&key=${GOOGLE_STREET_VIEW_API_KEY}`;
    try {
      const resp = await fetch(metaUrl);
      const data = await resp.json();
      return data.status === 'OK';
    } catch (err) {
      console.error('StreetView metadata error =>', err);
      return false;
    }
  };

  // On mount or whenever place changes, check coverage
  useEffect(() => {
    if (!place) return;
    (async () => {
      const address = place.Full_Address || '';
      const coverage = await checkStreetViewAvailabilityByAddress(address);
      setHasStreetView(coverage);
    })();
  }, [place]);

  // Overlays for utilities
  useEffect(() => {
    if (!showUtilities) {
      setLoadedOverlays([]);
      return;
    }
    const cityName =
      (place.city || place.City || place.city_name || '').toLowerCase();
    const cityOverlays = OVERLAY_CONFIGS.filter(
      (conf) => conf.city.toLowerCase() === cityName
    );
    setLoadedOverlays([]);

    if (cityOverlays.length > 0) {
      (async () => {
        try {
          const fetchPromises = cityOverlays.map((overlay) =>
            fetch(overlay.url)
              .then((r) => {
                if (!r.ok) throw new Error(`Failed => ${overlay.url}`);
                return r.json();
              })
              .then((data) => ({ ...overlay, data }))
          );
          const results = await Promise.all(fetchPromises);
          setLoadedOverlays(results);
        } catch (err) {
          console.error('Overlay fetch error =>', err);
        }
      })();
    }
  }, [showUtilities, place.city, place.City, place.city_name]);

  // Payment click
  const handlePaymentClick = async () => {
    try {
      const response = await axios.post('https://api.developiq.org/api', {
        m: 'getPaymentURL',
        tok: token,
      });
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      if (error.response) {
        alert(`Error: ${error.response.data.message || 'Unknown error'}`);
      } else if (error.request) {
        alert('No response from server.');
      } else {
        alert('An unexpected error occurred.');
      }
    }
  };

  // Helper for restricted items
  const renderRestrictedItem = (title) => (
    <div className="info-item-popup restricted-info-item-popup">
      <LockIcon className="lock-icon" />
      <strong className="info-title">{title}</strong>
    </div>
  );

  if (!place) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* Close button */}
        <button
          className="close-button"
          onClick={onClose}
          aria-label="Close Popup"
        >
          <CloseIcon />
        </button>

        <div className="popup-top">
          {/* LEFT: Either Street View (if coverage) OR fallback image */}
          <div
            className="popup-streetview-section"
            style={{ width: '50%', height: '340px', marginTop: '10px' }}
          >
            {googleMapsLoaded && hasStreetView ? (
              <StreetViewManual
                lat={Number(place.Latitude) || 36.1498}
                lng={Number(place.Longitude) || -94.1489}
                fallbackImageUrl={originalImageUrl}
              />
            ) : (
              <img
                src={originalImageUrl}
                alt={`Primary of ${place.Full_Address || 'Unknown Address'}`}
                onError={(e) => {
                  e.target.src = '';
                  console.error('Primary image load failed / coverage missing.');
                }}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </div>

          {/* RIGHT: Mapbox */}
          <div
            className="popup-map-section"
            style={{ width: '50%', height: '340px', marginTop: '10px' }}
          >
            <ReactMapGL
              {...viewport}
              width="100%"
              height="100%"
              mapStyle={
                showTopography
                  ? 'mapbox://styles/mapbox/outdoors-v12'
                  : 'mapbox://styles/mapbox/streets-v12'
              }
              onMove={(evt) => setViewport(evt.viewState)}
              mapboxAccessToken={MAPBOX_TOKEN}
            >
              {/* Marker */}
              <Marker
                latitude={Number(place.Latitude) || 36.1498}
                longitude={Number(place.Longitude) || -94.1489}
              >
                <img
                  src={markerIcon}
                  alt="Marker"
                  style={{ width: 32, height: 32, cursor: 'pointer' }}
                  onClick={() => setShowMarkerPopup(true)}
                />
              </Marker>

              {/* Overlays */}
              {loadedOverlays.map((overlay) => (
                <Source
                  key={overlay.id}
                  id={overlay.id}
                  type="geojson"
                  data={overlay.data}
                >
                  <Layer
                    id={`layer-${overlay.id}`}
                    type="line"
                    paint={{
                      'line-color':
                        overlay.type === 'water' ? '#007bff' : '#28a745',
                      'line-width': 2,
                    }}
                  />
                </Source>
              ))}

              {/* Marker Popup */}
              {showMarkerPopup && (
                <Popup
                  latitude={Number(place.Latitude) || 36.1498}
                  longitude={Number(place.Longitude) || -94.1489}
                  onClose={() => setShowMarkerPopup(false)}
                  closeOnClick={false}
                  anchor="top"
                >
                  <div>
                    <h3>{place.Full_Address || 'Unknown Address'}</h3>
                    <p>
                      Price: ${place.List_Price?.toLocaleString() || 'N/A'}
                    </p>
                    <p>
                      Acreage:{' '}
                      {place.Lot_Acreage
                        ? parseFloat(place.Lot_Acreage).toFixed(2)
                        : 'N/A'}
                    </p>
                  </div>
                </Popup>
              )}
            </ReactMapGL>

            {/* Toggles */}
            <div className="toggle-container">
              <div className="toggle-item">
                <label htmlFor="utilities-switch">Utilities</label>
                <Switch
                  id="utilities-switch"
                  checked={showUtilities}
                  onChange={(e) => setShowUtilities(e.target.checked)}
                />
              </div>
              <div className="toggle-item">
                <label htmlFor="topography-switch">Topography</label>
                <Switch
                  id="topography-switch"
                  checked={showTopography}
                  onChange={(e) => setShowTopography(e.target.checked)}
                />
              </div>
            </div>

            {/* Legend if showUtilities */}
            {showUtilities && (
              <div className="legend">
                <p>
                  <span className="legend-color legend-water"></span>Water
                </p>
                <p>
                  <span className="legend-color legend-sewer"></span>Sewage
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Price + Address */}
        <h2 className="popup-price">
          $
          {place.List_Price !== undefined
            ? parseFloat(place.List_Price).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            : 'Currently Unavailable'}
        </h2>
        <h3 className="popup-name">{place.Full_Address || 'Unknown Address'}</h3>
        <div className="popup-info">
          <p>
            Acreage:{' '}
            {place.Lot_Acreage
              ? formatNumber(place.Lot_Acreage)
              : 'Currently Unavailable'}
          </p>
          <p>Market Status: {place.Status || 'Currently Unavailable'}</p>
        </div>

        {/* Detailed Info Based on User Status */}
        {isPaidUser ? (
          <div className="popup-details">
            {/* Zoning & Utilities */}
            <div className="info-sections-row">
              <div className="info-section">
                <h4>Zoning</h4>
                <div className="info-item-popup">
                  <strong>Zoning Regulations</strong>
                  <p>{place.Zone || 'Currently Unavailable'}</p>
                </div>
                {/* NEW: Zoning Definition */}
                <div className="info-item-popup">
                  <strong>Zoning Definition</strong>
                  <p>{place.Zoning_Definition || 'Currently Unavailable'}</p>
                </div>
              </div>
              <div className="info-section">
                <h4>Utilities</h4>
                <div className="info-item-popup">
                  <strong>Sewage Access Distance</strong>
                  <p>
                    {place.Sewage_Dist_Meters
                      ? `${formatNumber(place.Sewage_Dist_Meters)} meters`
                      : 'Currently Unavailable'}
                  </p>
                </div>
                <div className="info-item-popup">
                  <strong>Water Access Distance</strong>
                  <p>
                    {place.Dist_to_Water
                      ? `${formatNumber(place.Dist_to_Water)} meters`
                      : 'Currently Unavailable'}
                  </p>
                </div>

                {/* Water Pipe Diameter */}
                <div className="info-item-popup">
                  <strong>Water Pipe Diameter</strong>
                  <p>
                    {place.Water_Pipe_Diameter
                      ? `${formatNumber(place.Water_Pipe_Diameter)} inches`
                      : 'Currently Unavailable'}
                  </p>
                </div>

                {/* Sewage Pipe Diameter */}
                <div className="info-item-popup">
                  <strong>Sewage Pipe Diameter</strong>
                  <p>
                    {place.Sewage_Pipe_Diameter
                      ? `${formatNumber(place.Sewage_Pipe_Diameter)} inches`
                      : 'Currently Unavailable'}
                  </p>
                </div>
              </div>
            </div>

            {/* Community Insights */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              <div className="info-item-popup">
                <strong>Closest Hospital</strong>
                <p>{place.Closest_Hospital || 'Currently Unavailable'}</p>
              </div>
              <div className="info-item-popup">
                <strong>Closest School</strong>
                <p>{place.Nearby_Schools || 'Currently Unavailable'}</p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Religious Building Distance</strong>
                <p>
                  {place.Dist_to_Church
                    ? `${formatNumber(place.Dist_to_Church)} mi`
                    : 'Currently Unavailable'}
                </p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Park</strong>
                <p>{place.Closest_Park || 'Currently Unavailable'}</p>
              </div>
              <div className="info-item-popup">
                <strong>Closest Park Distance</strong>
                <p>
                  {place.Dist_to_Park
                    ? `${formatNumber(place.Dist_to_Park)} mi`
                    : 'Currently Unavailable'}
                </p>
              </div>
            </div>

            {/* Price Comparison */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              <div className="info-item-popup">
                <strong>Median Price Per Acre Sold</strong>
                <p>
                  {place.Median_Price_Per_Acre_Sold
                    ? `$${parseFloat(
                        formatNumber(place.Median_Price_Per_Acre_Sold)
                      ).toLocaleString()} per acre`
                    : 'Currently Unavailable'}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="popup-details">
            <p
              className="pay-to-unlock-message"
              onClick={handlePaymentClick}
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              Pay here for this information
            </p>

            {/* Zoning & Utilities (Restricted) */}
            <div className="info-sections-row">
              <div className="info-section">
                <h4>Zoning</h4>
                {renderRestrictedItem('Zoning Regulations')}
                {renderRestrictedItem('Zoning Definition')}
              </div>
              <div className="info-section">
                <h4>Utilities</h4>
                {renderRestrictedItem('Sewage Access')}
                {renderRestrictedItem('Water Access')}
                {renderRestrictedItem('Water Pipe Diameter')}
                {renderRestrictedItem('Sewage Pipe Diameter')}
              </div>
            </div>

            {/* Community Insights (Restricted) */}
            <div className="info-section full-width">
              <h4>Community Insights</h4>
              {renderRestrictedItem('Closest Hospital')}
              {renderRestrictedItem('Closest School')}
              {renderRestrictedItem('Closest Religious Building Distance')}
              {renderRestrictedItem('Closest Park')}
              {renderRestrictedItem('Closest Park Distance')}
            </div>

            {/* Price Comparison (Restricted) */}
            <div className="info-section full-width">
              <h4>Price Comparison</h4>
              {renderRestrictedItem('Median Price Per Acre Sold')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultPopup;
